<script setup lang="ts">
import { Form, Field, ErrorMessage } from 'vee-validate';

const props = defineProps({
  single_prop_site_id: String
})


const GET_TOC = gql`
query {
single_prop_site_terms_and_conditions {
content
}
}`
const {data:termsandconditions} = await useAsyncQuery(GET_TOC)


const TOCOpen = ref(false)

const contactForm = ref({
  name: null,
  email: null,
  phone: null,
  message: null,
  accepted_privacy_policy: false,
  single_prop_site_id: props.single_prop_site_id,
})

function requiredField(value) {
  if (!value) {
    return 'This field is required';
  }
  return true
}
function validateEmail(value) {
  // if the field is empty
  if (!value) {
    return 'This field is required';
  }
  // if the field is not a valid email
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!regex.test(value)) {
    return 'This field must be a valid email';
  }
  // All is good
  return true;
}


/*const runtimeConfig = useRuntimeConfig();*/

const formSubmitted = ref(false);
const formError = ref(false);
/*const ADD_RECORD_CONTACT_FORM = gql`
mutation {
create_single_prop_site_leads_item
}`

const {mutate: addContactFormData} = useMutation(ADD_RECORD_CONTACT_FORM, {variables: {data: contactForm.value}})*/

const pushContactFormData = async () => {
  try {
    const {status} = await useTmarkApiData('single_prop_site_leads',
        {
          method: 'POST',
          body: contactForm.value
        })

    if (status.value == 'success') {
      formSubmitted.value = true;
    }

    else {
      formError.value = true
    }
  }
  catch(err) {
    console.log(err)
  }
}
</script>

<template>
  <div class="flex flex-col m-auto w-full">
    <div class="w-full md:w-1/2 m-auto flex text-6xl invert text-center transition ease-in opacity-80 hover:opacity-100 duration-500 outline p-4" v-if="formSubmitted">Contact Form Submitted.</div>
    <div class="w-full md:w-1/2 m-auto flex text-6xl invert text-center transition ease-in opacity-80 hover:opacity-100 duration-500 outline p-4" v-if="formError">There was an error submitting the form, please contact the agent directly.</div>
    <Form class="w-full md:w-1/2 mx-auto flex flex-col gap-4 p-4" @submit="pushContactFormData" v-if="formSubmitted == false">
      <div class="text-4xl m-auto invert">Have a Question?</div>
      <Field v-model="contactForm.name" name="name" type="text" class="rounded-lg w-full p-2" placeholder="*Name" :rules="requiredField" required/>
      <ErrorMessage name="name" class="invert"/>
      <Field v-model="contactForm.phone" name="phone"  type="phone" class="rounded-lg w-full p-2" :rules="requiredField" placeholder="*Phone" required/>
      <ErrorMessage name="phone" class="invert"/>
      <Field v-model="contactForm.email" name="email" type="email" :rules="validateEmail" class="rounded-lg w-full p-2" placeholder="*Email" required/>
      <ErrorMessage name="email" class="invert"/>
      <textarea v-model="contactForm.message" name="message" type="textarea" class="rounded-lg w-full p-2 min-h-[150px]" placeholder="Message" />
      <Field name="accepted_privacy_policy" :rules="requiredField" required>

        <label class="invert">
          <Field type="checkbox" v-model="contactForm.accepted_privacy_policy" name="accepted_privacy_policy" required :value="true"/>
          Accept <button @click.prevent="TOCOpen=!TOCOpen" class="text-c2 text-c2">Terms and Privacy Policy</button>
        </label>
        <ErrorMessage name="accepted_privacy_policy" class="invert"/>
      </Field>
      <button class="rounded-xl bg-c2 w-3/4 m-auto p-2 transition transform ease-in-out duration-300 enabled:hover:scale-110 disabled:bg-opacity-20" :disabled="!contactForm.accepted_privacy_policy" type="submit">Submit</button>
    </Form>
  </div>
  <div class="fixed inset-0 overflow-y-auto overscroll-none min-h-screen h-screen w-screen z-[1000] bg-c1" v-if="TOCOpen">
    <div class="flex flex-col z-[1000] overscroll-contain w-full h-full">
    <button class="sticky top-4 text-6xl text-right p-8 z-[1001]" @click.prevent="TOCOpen=!TOCOpen">X</button>
    <div class="text-6xl text-center invert">Terms & Conditions</div>
    <div class="invert p-8 text-left h-fit" v-html="termsandconditions?.single_prop_site_terms_and_conditions?.content"/>
    </div>

  </div>
</template>

<style scoped>

</style>