<script setup lang="ts">
import {getImageUrl} from "~/functions/getImageUrl";
import {createI18n} from "vue-i18n";
import {NavLink} from "#components"
import Fancybox from "~/components/Fancybox.vue";
import FooterComponent from "~/components/FooterComponent.vue";
import LeafletMap from "~/components/LeafletMap.vue";

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
  }
}
const i18n = createI18n({numberFormats})

function scrollTo($id:String) {
  document.getElementById($id).scrollIntoView({behavior:"smooth"})
}


const props = defineProps({
  data:null
})
</script>

<template>
  <div class="h-screen flex relative" v-if="data">
    <div class="absolute top-0 w-full bg-c1 h-14 flex z-40 justify-between">
      <div class="w-fit bg-c2 h-full flex"><div class="m-auto p-2 text-c3">{{data?.agent_info?.first_name}} {{data?.agent_info?.last_name}}</div></div>
      <div class="my-auto z-40 flex w-fit gap-8 text-c3 px-4">
        <NavLink name="Description" @click.prevent="scrollTo('#description')"/>
        <NavLink name="Gallery" href="/" @click.prevent="scrollTo('#gallery')"/>
      </div>
    </div>

    <img v-if="data && data?.header_image" class="z-30 absolute top-0 h-full w-full object-cover" :src="getImageUrl(data?.header_image.id)"/>

    <div class="absolute bottom-0 flex h-fit w-fit aspect-video bg-c2 bg-opacity-60 text-6xl text-c3 z-40">
      <div class="m-auto max-w-40 px-4" v-if="data">{{data?.street_address}}</div>
    </div>
  </div>

  <div class="w-full h-fit py-8 flex flex-col relative bg-c3" v-if="data">
<!--    <div class="text-center text-6xl p-4 pb-8 text-c1" v-if="data ">Offered at ${{ data.asking_price.toLocaleString('en-US') }}</div>-->
    <div class="mx-auto grid grid-cols-3 gap-4 md:gap-8 justify-between p-4 w-full md:w-1/2 z-40">
      <div class="w-full aspect-square my-auto bg-c2 flex flex-col outline outline-c5 rounded-xl text-center shrink">
        <div class="m-auto text-c3 text-2xl md:text-4xl lg:text-6xl" v-if="data"><p>{{data?.beds}}</p><p class="text-xl md:text-4xl">Beds</p></div>

      </div>
      <div class="w-full aspect-square my-auto bg-c2 flex outline outline-c5 rounded-xl text-center shrink">
        <div class="m-auto text-c3 text-2xl md:text-4xl lg:text-6xl" v-if="data"><p>{{data?.full_baths}}.{{data?.half_baths}}</p><p class="text-2xl md:text-4xl">Baths</p></div>
      </div>
      <div class="w-full aspect-square my-auto bg-c2 flex outline outline-c5 rounded-xl text-center shrink">
        <div class="m-auto text-c3 text-2xl md:text-4xl lg:text-6xl" v-if="data"><p>{{data?.sqft}}</p><p class="text-2xl md:text-4xl text-c3">SF</p></div>
      </div>
    </div>
    <div class="w-full md:w-3/4 mx-auto flex flex-wrap gap-x-4 gap-y-6 pt-8 px-4 place-content-center" v-if="data">
      <div class="outline w-fit rounded-xl px-2" v-for="item in data?.features">{{item}}</div>
    </div>

    <div class="w-full md:w-3/4 pt-8 mx-auto" id="#description">
      <h2 class="text-6xl text-center underline">
        Description
      </h2>
      <p class="pt-6 px-4" v-if="data">
        {{data?.description}}
      </p>
    </div>
  </div>

  <div class="h-fit flex flex-col">
    <h2 class="text-6xl text-center pt-8 underline">Gallery</h2>
    <Fancybox class="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 h-fit p-8 gap-4" id="#gallery" v-if="data && data?.gallery">
      <a :href="getImageUrl(item.directus_files_id?.id)" data-fancybox="gallery" :data-caption="item.directus_files_id?.title" v-for="item in data?.gallery">
        <img class="h-50 aspect-video object-cover" :src="getImageUrl(item.directus_files_id?.id)"/>
      </a>
    </Fancybox>
  </div>

  <LeafletMap :data="data"/>
<FooterComponent :data="data"/>
</template>

<style scoped>

</style>