<script setup lang="ts">
import {SymbolKind} from "vscode-languageserver-types";
import Array = SymbolKind.Array;


const showMenu = ref(false)
const props=defineProps({
  navItems:Array
})

function scrollTo($id:String) {
  showMenu.value = false
  document.getElementById($id).scrollIntoView({behavior:"smooth"})
}
</script>

<template>
  <UIcon name="i-line-md-menu" class="h-8 md:h-12 w-8 md:w-12 transition-transform hover:scale-105" @click="showMenu=true" dynamic/>
  <USlideover class="h-screen w-screen flex overflow-hidden overscroll-none" v-model="showMenu">
<UIcon name="i-line-md-close" class="h-8 md:h-12 w-8 md:w-12 outline-none absolute top-4 right-4 bg-transparent text-black transition-transform hover:bg-transparent hover:scale-105 outline-none " @click="showMenu=false" dynamic/>
    <div class="pt-28 px-8 text-5xl text-right h-fit">
      <div class="flex flex-col w-full mx-auto gap-20">
        <NavLink class="font-accent text-5xl" v-for="item in navItems" :name="item.name" @click="scrollTo(item.href)"/>
      </div>

    </div>

  </USlideover>
</template>

<style scoped>

</style>