import {useCookie} from "#app";


export default defineNuxtPlugin((nuxtApp) => {
    const cookie = useCookie('apollo:default.token')

    nuxtApp.hook('apollo:auth', ({client, token}) => {
        token.value = "WEjZl0gQGKe2ru40UqazUUCQ4Fo07Ltd"
    })
})
