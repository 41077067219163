
<script setup lang="ts">
import {getImageUrl} from "~/functions/getImageUrl";
import {createI18n} from "vue-i18n";
import {NavLink} from "#components"
import Fancybox from "~/components/Fancybox.vue";
import FooterComponent from "~/components/FooterComponent.vue";
import LeafletMap from "~/components/LeafletMap.vue";
import CarouselFancybox from "~/components/CarouselFancybox.vue";
import FeatureItem from "~/components/FeatureItem.vue";
import PhotoCard from "~/components/PhotoCard.vue";
import PhotoGallery from "~/components/PhotoGalleryComponents/PhotoGallery.vue";
import SummaryComponentv1 from "~/components/summarySection/SummaryComponentv1.vue";
import PhotoGalleryv2 from "~/components/PhotoGalleryComponents/PhotoGalleryv2.vue";
import ListingInfoComponentv1 from "~/components/listingInfoSection/ListingInfoComponentv1.vue";


const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
  }
}
const i18n = createI18n({numberFormats})



const props = defineProps({
  data:null
})

const showDescription = ref(false)

const toggleAndScrollDescription = (id) => {
  showDescription.value = !showDescription.value;
  scrollTo(id);
}

const navItems = [{name: "Description", href: "#description"}, {name: "Gallery", href: "#gallery"}, {name: "Listing Info", href:"#listinginfo"}]

const DescriptionReadMore = ref(false)


const listingInfoData = ref([{icon:'i-heroicons-chevron-right-20-solid', label:'Gallery', slot:'gallery',gallery:props.data.gallery, video_embed:props?.data?.video_embed, id:'#gallery'},
  {icon:'i-hreoicons-chevron-right-20-solid', label:'Listing Information', data: props.data, slot: 'listingInfo', id: '#listinginfo'}])
</script>

<template>
    <div class="h-fit md:h-screen flex flex-col relative bg-white md:bg-black">

    <div class="absolute top-0 w-full h-20 flex z-40 justify-between">
      <div class="h-20 w-20 aspect-1/1 flex"><img class="rounded-full p-2 object-cover" :src="getImageUrl(data?.agent_info?.avatar?.id)" alt="Listing Agent Avatar"/></div>
      <div class="my-auto z-40 flex w-fit gap-8 text-c3 px-4">
        <NavComponent :nav-items="navItems"/>
      </div>
    </div>

<!--    <img v-if="data && data?.header_image" class="z-30 absolute bottom-0 h-full w-full object-cover fade-in-leave-to" :src="getImageUrl(data?.header_image.id)"/>-->
      <img v-if="data && data?.header_image" class="z-30 relative md:absolute md:bottom-0 md:h-full md:w-full object-cover" :src="getImageUrl(data?.header_image.id)" alt="Header Image"/>
    <div class="relative md:absolute bottom-0 flex flex-col gap-2 md:gap-8 h-fit md:min-h-48 max-h-fit w-full text-black md:text-c3 z-40 p-2 md:p-4 text-left bg-c4 md:bg-transparent" v-if="data">
      <div class="text-4xl md:text-8xl font-accent">{{data?.street_address}}</div>
      <div class="flex justify-between ">

        <div class="font-accent text-2xl text-left flex gap-2 p-2"><div class="text-lg opacity-95">Offered at</div> <div class="text-3xl">${{data.asking_price.toLocaleString('en-US')}}</div></div>
      </div>
    </div>
  </div>
  <SummaryComponentv1 :data="data"/>








  <div class="w-4/5 mx-auto h-fit flex z-10 relative flex-col-reverse lg:flex-row snap-y snap-mandatory pt-4 md:pt-8 gap-20" id="#description">
<!--      <div class="h-full flex w-full flex-row" v-if="data?.gallery">
        <PhotoCard :data="data" class="h-96 aspect-4/3" gallery-id="galleryBox"/>
      </div>-->

        <PhotoCard :data="data" class="w-full aspect-4/3 m-auto object-cover" gallery-id="galleryBox"/>

      <div class="text-2xl md:text-lg text-justify  transition-transform duration-500 ease-in-out flex z-100 h-fit flex flex-col my-auto w-full" v-motion-fade v-if="data" alt="Property Description" >
        <UDivider>
          <h2 class="text-2xl mx-auto font-accent">Property Description</h2>
        </UDivider>
        <p class="z-10 p-2 snap-center line-clamp-[10]">
        {{data.description}}
        </p>
        <UButton label="Read Full Description" @click="DescriptionReadMore=true" class="bg-c1 hover:bg-c2 w-fit"/>

      </div>

    </div>

  <UDivider class="p-12"><div class="text-3xl">Features</div></UDivider>
  <div class="flex flex-wrap w-full md:w-5/6 m-auto gap-2 md:gap-8 justify-center md:justify-between">
    <div class="text-black border border-1 rounded-xl flex text-center font-accent font-light p-2 text-md" v-motion-pop-visible :delay="200" v-for="item in data.features">{{item}}</div>
  </div>



  <ListingInfoComponentv1 :data="listingInfoData" v-if="listingInfoData"/>


  <UModal v-model="DescriptionReadMore" fullscreen class="flex">
    <UButton label="Close Property Description" @click="DescriptionReadMore=false" class="bg-black absolute top-4 right-4 transition duration-200 hover:bg-transparent hover:text-black"><UIcon name="i-line-md-close" class="h-8 md:h-12 w-8 md:w-12" dynamic/> </UButton>
    <div class="pt-28 px-8 text-lg overflow-y-scroll">
      <div class="flex flex-col w-full md:w-2/3 mx-auto">
        <div class="text-3xl font-header text-center">Full Property Description</div>
        <div class="font-body text-lg md:text-xl py-8 leading-loose">{{data?.description}}</div>
      </div>

    </div>
  </UModal>



<!--  <div v-if="data">
    {{data}}
  </div>-->


<!--  <div class="h-fit flex flex-col px-8 w-full mx-auto overflow-hidden" id="#gallery">
      <CarouselFancybox gallery-id="galleryBox" class="" :data="data?.gallery"/>
    </div>-->





<!--  <LeafletMap :data="data" class="z-10"/>-->
</template>

<style scoped>

</style>