<script setup lang="ts">
import {getImageUrl} from "~/functions/getImageUrl";

const props = defineProps({
  agent:null,
  id:null
})

const readBio = ref(false)
</script>

<template>
<div class="h-fit w-96 max-w-full flex flex-col m-auto px-8" :id="id">
  <UCard>
    <template #header>
      <div class="h-fit flex flex-col align-baseline">
        <div class="text-3xl font-accent">{{agent?.first_name}} {{agent?.last_name}}</div>
        <div class="text-xl font-accent opacity-75">Listing Agent</div>
      </div>

    </template>

    <div class="flex flex-col gap-4">
      <img class="w-64 h-64 m-auto bg-black rounded-full object-cover shadow-md" :src="getImageUrl(agent.avatar.id)" alt="Listing Agent Avatar"/>
      <span class="font-light text-lg ">{{agent?.company?.name}}</span>
      <a class="flex gap-4" :href="`tel:`+agent?.phone">
        <UIcon name="i-mdi-phone-outline" class="h-8 w-8" dynamic/><div class="text-lg">{{agent.phone}}</div>
      </a>
      <a class="flex gap-4" :href="`mailto:`+agent?.email">
        <UIcon name="i-mdi-mail-outline" class="h-8 w-8" dynamic/><div class="text-lg">{{agent.email}}</div>
      </a>

      <div class="flex gap-4 h-fit justify-end" v-if="agent" :class="{'justify-between': agent.biography}">
        <UButton label="Read Bio" color="c1" class="h-fit" @click="readBio=true" v-if="agent.biography"/>
        <div class="flex gap-4 h-fit justify-end flex-wrap">
        <a :href="agent?.facebook" v-if="agent.facebook" aria-description="hello"><UIcon name="i-mdi-facebook" class="h-8 w-8 hover:animate-bounce hover:text-black" dynamic v-motion-pop-visible :delay="200"/></a>
        <a :href="agent?.instagram" v-if="agent.instagram"><UIcon name="i-mdi-instagram" class="h-8 w-8 hover:animate-bounce hover:text-black" dynamic v-motion-pop-visible :delay="200"/></a>
        <a :href="agent?.tiktok" v-if="agent.tiktok"><UIcon name="i-fa-brands-tiktok" class="h-8 w-8 hover:animate-bounce hover:text-black" dynamic v-motion-pop-visible :delay="200"/></a>

        </div>
      </div>
    </div>
  </UCard>
</div>
  <UModal v-model="readBio" class="flex h-screen" fullscreen>
    <UIcon @click="readBio=false" name="i-mdi-close" class="h-12 w-12 absolute top-4 right-4 hover:scale-105 overflow-x-hidden" dynamic/>
    <div class="agent-info"><div class="w-5/6 lg:w-1/2 m-auto text-2xl" v-html="agent.biography"/></div>

  </UModal>
</template>

<style>
.agent-info {
  @apply text-left py-28 px-8 flex flex-col gap-2 overflow-y-scroll;
}
.agent-info h1 {
  @apply text-4xl font-accent;
}

.agent-info h2 {
  @apply text-3xl font-accent;
}

.agent-info h3 {
  @apply text-2xl font-accent;
}
</style>