const GET_DATA = gql`
    query getInfo($id:String!) {
        single_prop_sites(filter: {id: {_eq:$id}}) {
            id
            agent_info {
                first_name
                last_name
                email
                phone
                company {
                    name
                }
                avatar {
                    id
                }
                facebook
                instagram
                tiktok
                biography
            }
            street_address
            asking_price
            city {name}
            state {name}
            zip_code
            description
            features
            header_image {id}
            gallery {directus_files_id {id, filename_disk, title}}
            lat
            lng
            status
            video_link
            beds
            full_baths
            half_baths
            sqft
            agent_additional_links
            site_version {
                name
            }
            description_image {
                id
            }
            lot_size
            stories
            subdivision {
                name
            }
            single_prop_site_share_docs {
                name
                file {
                    directus_files_id {
                        id
                    }
                }
            }
            video_embed

        }
        single_prop_site_default_disclosure {
            equal_housing_logo {
                id
                title
            }
        }
    }
`


export default GET_DATA