

<template>
  <Homev1 :data="pageInfo?.single_prop_sites[0]" v-if="pageInfo?.single_prop_sites[0]?.site_version?.name === 'v1'"/>
  <Homev2 :data="pageInfo?.single_prop_sites[0]" v-if="pageInfo?.single_prop_sites[0]?.site_version?.name === 'v2'" />
  <FooterComponent :data="pageInfo?.single_prop_sites[0]" :disclosure="pageInfo?.single_prop_site_default_disclosure"/>
</template>
<script setup lang="ts">

import {createI18n} from "vue-i18n";
import FooterComponent from "~/components/FooterComponent.vue";
import GET_DATA from "~/queries/getData";
import Homev1 from "~/components/pageVersion/homev1.vue";
import Homev2 from "~/components/pageVersion/homev2.vue";


const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD', notation: 'standard'
    },
  }
}
const i18n = createI18n({numberFormats})

function scrollTo($id:String) {
  document.getElementById($id).scrollIntoView({behavior:"smooth"})
}


const config = useRuntimeConfig()

const {data:pageInfo} = await useAsyncQuery(GET_DATA, {id: config.public.propertyID})
</script>
<style>
html {
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #353535;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e7397;
}
</style>